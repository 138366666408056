<template>
  <div>
    <Menu class="pc" style="position:fixed;top:0;left:0;right:0;background-color:#444" theme="dark" mode="horizontal" active-name="1">
      <MenuItem name="1" to="/">
        <Icon type="ios-home" />
        首页
      </MenuItem>
      <MenuItem name="2" to="/meaning">
        <Icon type="logo-freebsd-devil" />
        牌意
      </MenuItem>
      <!-- <MenuItem name="3" to="/404">
        <Icon type="logo-snapchat" />
        牌阵
      </MenuItem> -->
      <MenuItem name="4" to="/divine">
        <Icon type="ios-color-wand" />
        在线占卜
      </MenuItem>
      <div v-if="!$store.state.accountInfo.nickname" style="position: absolute;right : 20px; top: 15px;display:flex;justify-content:center; width: 50px">
        <Button icon="ios-contact" type="text" style="color: white; font-weight: bold" @click="$router.push(`/login?next=${$route.path}`)" ghost>登录</Button>
      </div>
      <div v-else style="position: absolute;right : 40px; top: 15px;display:flex;justify-content:center; width: 100px">
        <Button icon="ios-contact" type="text" style="color: white; font-weight: bold" ghost>{{ $store.state.accountInfo.nickname }}</Button>
        <Button icon="ios-exit-outline" type="text" style="color: white; font-weight: bold" @click="logout" ghost></Button>
      </div>
    </Menu>
    <div class="mobile">
      <div style="height: 100%;width: 150px;display: flex;align-items: center">
        <div style="display:flex;justify-content:center; width: 50px">
          <Button icon="md-menu" type="text" style="color: white" @click="navDrawer = true"></Button>
        </div>
        <div style="padding-top: 2px"><span style="color: white; font-weight: bold">{{ routeName }}</span></div>
      </div>
      <div v-if="!$store.state.accountInfo.nickname" style="position: absolute;right : 20px; top: 15px;display:flex;justify-content:center; width: 50px">
        <Button icon="ios-contact" type="text" style="color: white; font-weight: bold" @click="$router.push(`/login?next=${$route.path}`)" ghost>登录</Button>
      </div>
      <div v-else style="position: absolute;right : 40px; top: 15px;display:flex;justify-content:center; width: 100px">
        <Button icon="ios-contact" type="text" style="color: white; font-weight: bold" ghost>{{ $store.state.accountInfo.nickname }}</Button>
        <Button icon="ios-exit-outline" type="text" style="color: white; font-weight: bold" @click="logout" ghost></Button>
      </div>
      <div>
        <Drawer placement="left" :closable="false" v-model="navDrawer">
          <div style="width: 100%;margin:10px 0"><Button style="width: 100%;font-size:12pt" icon="ios-home" type="text" @click="routeName = '首页'; $router.push('/');navDrawer=false">首页</Button></div>
          <div style="width: 100%;margin:10px 0"><Button style="width: 100%;font-size:12pt" icon="ios-bulb" type="text" @click="routeName = '牌意'; $router.push('/meaning');navDrawer=false">牌意</Button></div>
          <div style="width: 100%;margin:10px 0"><Button style="width: 100%;font-size:12pt" icon="ios-color-wand" type="text" @click="routeName = '占卜'; $router.push('/divine');navDrawer=false">占卜</Button></div>
          <template slot="header">
            <div style="text-align: center">
              <h3>前往</h3>
            </div>
          </template>
        </Drawer>
      </div>
    </div>
  </div>
</template>
<style scoped>
@media screen and (max-width: 768px) {
  .mobile {
    display: block !important;
    background: rgb(68, 68, 68);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 60px;
    z-index: 1;
    font-size: 13pt;
  }
  ::v-deep .ivu-btn:hover {
    background: #00000000;
  }
  .pc {
    display: none! important;
  }
}
.mobile {
  display: none;
}
</style>
<script>
export default {
  name: "Navbar",
  data () {
    return {
      navDrawer: false,
      routeName: '首页'  
    }
  },
  created () {
    if(this.$route.path.match(/meaning/)) {
      this.routeName = '牌意'
    } else if(this.$route.path.match(/divine/)) {
      this.routeName = '占卜'
    }
  },
  methods: {
    logout () {
      this.axios.get(`${this.HOST}/accounts/logout/`).then(() => {
        this.$Message.info('注销成功')
        localStorage.removeItem('accountInfo')
        this.$router.go(0)
      })
    }
  }
};
</script>
