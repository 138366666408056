<template>
<div style="width: 100%;display:flex;justify-content: center">
  <div style="width: 300px">
    <div style="margin: 50px;text-align: center"><h1>登录</h1></div>
    <Form>
      <FormItem label="用户名">
        <Input placeholder="" v-model="username" />
      </FormItem>
      <FormItem label="密码">
        <Input type="password" placeholder="" v-model="password" />
      </FormItem>
      <div style="color: #2d8cf0; width: 100px;margin-bottom: 15px; cursor:pointer" @click="$router.push('/register')">新用户注册</div>
      <FormItem>
         <Button type="primary" style="width: 100%" @click="login">登录</Button>
      </FormItem>
    </Form>
  </div>
</div>
</template>
<script>
export default {
  data () {
    return {
      username: '',
      password: ''
    }
  },
  created () {
    if (this.$store.state.accountInfo.username) {
      this.$router.push('/')
    }
  },
  methods: {
    login () {
      this.axios.get(`${this.HOST}/accounts/login/`, {
        params: {
          username: this.username,
          password: this.password
        }
      }).then(res => {
        if (res.data.errno === 0) {
          this.$Message.success(res.data.message)
          this.$store.commit('loginSuccess', res.data.accountInfo)
          localStorage.setItem('accountInfo', JSON.stringify(res.data.accountInfo))
          this.$router.push(this.$route.query.next ? this.$route.query.next : '/')
        } else {
          this.$Message.error(res.data.message)
        }
      })
    }
  }
}
</script>
