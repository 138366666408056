<template>
  <div>
    <div class="pc" style="text-align: center">
      <div style="margin-top: 15px">
        <Button type="success" style="margin-right: 10px">塔罗牌</Button>
        <Button type="primary" @click="$router.push('/divineastro')">占星骰子</Button>
      </div>
      <div style="margin-top: 50px; margin-bottom: 20px">
        <div v-if="this.$store.state.accountInfo.is_superuser" style="margin: 20px">
          <Input style="width: 300px" placeholder="唯一编号" v-model="id" />
          <Button style="margin-left: 10px" type="primary" @click="review"
            >查询</Button
          >
        </div>
        <div>
          <RadioGroup
            v-if="load === false"
            v-model="cnt"
            type="button"
            button-style="solid"
          >
            <Radio label="1" :disabled="preventChange"></Radio>
            <Radio label="2" :disabled="preventChange"></Radio>
            <Radio label="3" :disabled="preventChange"></Radio>
            <Radio label="4" :disabled="preventChange"></Radio>
            <Radio label="5" :disabled="preventChange"></Radio>
            <Radio label="6" :disabled="preventChange"></Radio>
            <Radio label="7" :disabled="preventChange"></Radio>
            <Radio label="8" :disabled="preventChange"></Radio>
            <Radio label="9" :disabled="preventChange"></Radio>
          </RadioGroup>
          <Button
            style="margin-left: 10px"
            type="warning"
            @click="redo(cnt)"
            >重新抽取</Button
          >
        <Button
          :disabled="!this.$store.state.accountInfo.username"
          style="margin-left: 10px"
          type="info"
          @click="drawer = true"
          >历史记录</Button
        >
        </div>
        <div>
          <div v-if="load === false">
            <Input
              :disabled="preventChange"
              v-model="question"
              maxlength="1000"
              show-word-limit
              type="textarea"
              :placeholder="preventChange && question.trim() === '' ? '本次抽取没有输入问题。' : '请先输入您的问题，之后再依次翻开塔罗牌。'"
              style="margin-top: 20px; width: 600px"
            />
          </div>
          <div v-else style="padding: 50px 100px 20px 100px ;word-break: break-all;word-wrap: break-word;" ><span v-html="question" /></div>
        </div>
      </div>
      <div style="display: flex; justify-content: center">
        <div
          style="
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            width: 650px;
          "
        >
          <div
            style="position: relative; margin: 15px"
            v-for="idx in numList.slice(0, cnt)"
            :key="idx"
          >
            <img
              ref="unknowncard"
              src="@/assets/card-back.jpg"
              style="border-radius: 5px; cursor: pointer"
              height="300"
              @click="getCard(idx)"
            />
            <img 
              ref="card"
              style="cursor: pointer"
              height="300"
              :src="cardUrl[idx]"
              @click="getMeaning(idx)"
            />
          </div>
        </div>
      </div>
      <div style="margin: 20px">
        <span v-html="successMsg"></span>
      </div>
    </div>
    <div class="mobile">
      <div style="margin-top: 15px; display:flex; justify-content: center">
        <Button type="success" style="margin-right: 10px">塔罗牌</Button>
        <Button type="primary" @click="$router.push('/divineastro')">占星骰子</Button>
      </div>
      <div
        style="
          display: flex;
          justify-content: center;
          margin: 40px 20px 10px 20px;
        "
      >
        <div v-if="this.$store.state.accountInfo.is_superuser">
          <Input style="width: 150px" placeholder="唯一编号" v-model="id" />
          <Button
            style="width: 100px; margin-left: 10px"
            type="primary"
            @click="review"
            >查询</Button
          >
        </div>
      </div>
      <div style="display: flex; justify-content: center; margin: 10px 20px 10px 20px;">
        <Select style="width: 50%" :disabled="preventChange" v-model="cnt">
          <Option value="1" >1</Option>
          <Option value="2">2</Option>
          <Option value="3">3</Option>
          <Option value="4">4</Option>
          <Option value="5">5</Option>
          <Option value="6">6</Option>
          <Option value="7">7</Option>
          <Option value="8">8</Option>
          <Option value="9">9</Option>
        </Select>
        <Button
          style="width: 25%; margin-left: 10px"
          type="warning"
          @click="redo(cnt)"
          >重抽</Button
        >
        <Button
          :disabled="!this.$store.state.accountInfo.username"
          style="width: 25%; margin-left: 10px"
          type="info"
          @click="drawer = true"
          >历史</Button
        >
      </div>
      <div style="display:flex;justify-content: center;margin: 10px 20px 20px 20px;">
        <div style="width: 100%" v-if="load === false">
          <Input
            :disabled="preventChange"
            v-model="question"
            maxlength="1000"
            show-word-limit
            type="textarea"
            :placeholder="preventChange && question.trim() === '' ? '本次抽取没有输入问题。' : '请先输入您的问题，之后再依次翻开塔罗牌。'"
            style="width: 100%"
          />
        </div>
        <div v-else style="padding: 10px ;word-break: break-all;word-wrap: break-word;" ><span v-html="question" /></div>
      </div>
      <div>
        <div
          v-if="parseInt(cnt) % 3 === 0"
          style="display: flex; flex-wrap: wrap; justify-content: space-between; padding: 10px 20px 20px 20px;"
        >
          <div
            style="position: relative;width: 30%"
            v-for="idx in numList.slice(0, cnt)"
            :key="idx"
          >
            <img
              ref="unknowncardm"
              src="@/assets/card-back.jpg"
              style="border-radius: 5px; cursor: pointer"
              @click="getCard(idx)"
              width="100%"
            />
            <img
              v-show="result.length > idx"
              ref="cardm"
              style="cursor: pointer"
              :src="cardUrl[idx]"
              @click="getMeaning(idx)"
              width="100%"
            />
          </div>
        </div>
        <div
          v-else
          style="display: flex; flex-wrap: wrap; justify-content: space-around; padding: 10px 20px 20px 20px;"
        >
          <div
            style="position: relative;width: 30%"
            v-for="idx in numList.slice(0, cnt)"
            :key="idx"
          >
            <img
              ref="unknowncardm"
              src="@/assets/card-back.jpg"
              style="border-radius: 5px; cursor: pointer"
              @click="getCard(idx)"
              width="100%"
            />
            <img
              v-show="result.length > idx"
              ref="cardm"
              style="cursor: pointer;"
              :src="cardUrl[idx]"
              @click="getMeaning(idx)"
              width="100%"
            />
          </div>
        </div>
        <div style="margin: 20px;text-align: center">
          <span v-html="successMsg"></span>
        </div>
      </div>
      <Drawer title="历史记录" :closable="false" v-model="drawer">
        <div class="history-item" style="cursor: pointer; padding: 10px" @click.stop="id = item.id;review();drawer = false" v-for="(item, index) in history" :key="item.id">
          <div><span><b>唯一编号：{{ item.id }}</b></span></div>
          <div style="overflow: hidden"><span style="color: grey;">{{item.question.length > 12? `${item.question.substr(0, 12)}...` : `${item.question}`}}</span></div>
          <div>{{ item.create_time }}</div>
          <div style="display:flex;  align-items: center;width: 200px; flex-wrap: wrap;margin-top: 5px">
            <div style="margin-right: 10px" v-for="(imgIndex, idx) in item.result.replace(/\[/g, '').replace(/\]/g, '').replace(/ /g, '').split(',')" :key="index * 100 + imgIndex">
              <img style="border-radius: 3px;box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);" v-if="item.direct.replace(/\[/g, '').replace(/\]/g, '').replace(/ /g, '').split(',')[idx] === '0'" width="55px" :src="require(`@/assets/divine/${imgIndex}.jpg`)">
              <img style="transform: rotate(180deg);border-radius: 3px;box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);" width="55px" v-else :src="require(`@/assets/divine/${imgIndex}.jpg`)">
            </div>
          </div>
        </div>
      </Drawer>
    </div>
    <Modal
        v-model="meaningModal"
        :title="selectCardTitle"
        cancel-text=""
    >
      <p v-html="selectCardMeaning"></p>
    </Modal>
  </div>
</template>
<style scoped>
::v-deep .ivu-radio-group-button .ivu-radio-wrapper-disabled.ivu-radio-wrapper-checked {
  background: #2d8cf0;
}
::v-deep .ivu-select-disabled .ivu-select-selection {
  color: black
}
.history-item:hover {
  background: lightgrey;
  border-radius: 5px;
  transition: .25s all ease;
}
.mobile {
  display: none;
}
@media only screen and (max-width: 768px) {
  .pc {
    display: none;
  }
  .mobile {
    display: block;
  }
}
@keyframes unknowncard-spin {
  from {
    opacity: 1;
    transform: rotateY(0deg);
  }
  50% {
    opacity: 1;
    transform: rotateY(90deg);
  }
  51% {
    opacity: 0;
    transform: rotateY(90deg);
  }
  to {
    opacity: 0;
    transform: rotateY(180deg);
  }
}
@keyframes card-spin {
  from {
    opacity: 1;
    transform: rotateY(90deg);
  }
  50% {
    opacity: 1;
    transform: rotateY(90deg);
  }
  to {
    opacity: 1;
    transform: rotateY(0deg);
  }
}
@keyframes card-spin-invert {
  from {
    opacity: 1;
    transform: rotateX(180deg) rotateY(90deg);
  }
  50% {
    opacity: 1;
    transform: rotateX(180deg)  rotateY(90deg);
  }
  to {
    opacity: 1;
    transform: rotateX(180deg) rotateY(0deg);
  }
}
.unknowncard {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  animation: unknowncard-spin 1s ease;
  animation-fill-mode: forwards;
}
.card {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  border-radius: 7px;
  opacity: 0;
  animation: card-spin 1s ease;
  animation-fill-mode: forwards;
  position: absolute;
  top: 0;
  left: 0;
}
.card-invert {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  border-radius: 7px;
  opacity: 0;
  animation: card-spin-invert 1s ease;
  animation-fill-mode: forwards;
  position: absolute;
  top: 0;
  left: 0;
}
</style>
<script>
export default {
  name: "Divine",
  data() {
    return {
      decks: [],
      numList: [0, 1, 2, 3, 4, 5, 6, 7, 8],
      cnt: "3",
      cardUrl: new Array(),
      exists: [],
      result: [],
      direct: [],
      successMsg: "",
      id: "",
      question: "",
      load: false,
      history: [],
      drawer: false,
      forbidden: false,
      preventChange: false,
      meaningModal: false,
      selectCardTitle: '',
      selectCardMeaning: ''
    };
  },
  created() {
    this.axios.get("/assets/decks.json").then((res) => {
      this.decks = res.data;
    });
  },
  mounted() {
    this.getHistory()
  },
  methods: {
    getHistory () {
      this.axios.get(`${this.HOST}/divine/`).then(res => {
        this.history = res.data.data
        for (const idx in this.history) {
          this.history[idx].create_time = this.history[idx].create_time.replace('T', ' ').split('.')[0]
        }
      })
    },
    getMeaning(idx) {
      if (this.result.length !== parseInt(this.cnt)) return
      if(parseInt(this.direct[idx]) === 1) {
        this.selectCardTitle = `${this.decks[this.result[idx]].name} - 逆位`
        this.selectCardMeaning = this.decks[this.result[idx]].invert
      } else {
        this.selectCardTitle = `${this.decks[this.result[idx]].name} - 正位`
        this.selectCardMeaning = this.decks[this.result[idx]].normal
      }
      this.meaningModal = true
    },
    getCard(idx) {
      if (idx < parseInt(this.result.length) || (idx !== 0 && this.result[idx - 1] === undefined)) {
        return;
      }
      this.$refs.card[idx].classList = [];
      this.$refs.cardm[idx].classList = [];
      this.$refs.unknowncard[idx].classList = [];
      this.$refs.unknowncardm[idx].classList = [];
      let result;
      do {
        result = Math.floor((Math.random() * 78) % 78);
      } while (!(this.exists.indexOf(result) === -1));
      this.exists.push(result);
      let direct = Math.floor((Math.random() * 2) % 2);
      if (direct === 0) {
        this.$refs.card[idx].classList = ["card"];
        this.$refs.cardm[idx].classList = ["card"];
        this.$refs.unknowncard[idx].classList = ["unknowncard"];
        this.$refs.unknowncardm[idx].classList = ["unknowncard"];
      } else {
        this.$refs.card[idx].classList = ["card-invert"];
        this.$refs.cardm[idx].classList = ["card-invert"];
        this.$refs.unknowncard[idx].classList = ["unknowncard"];
        this.$refs.unknowncardm[idx].classList = ["unknowncard"];
      }
      this.$set(this.cardUrl, idx, require(`@/assets/divine/${result}.jpg`));
      this.$set(this.result, idx, result);
      this.$set(this.direct, idx, direct);
    },
    redo(oldV) {
      this.exists = [];
      this.result = [];
      this.direct = [];
      this.load = false;
      this.question = '';
      this.successMsg = '';
      this.forbidden = false;
      for (let i = 0; i < oldV; i++) {
        this.$refs.card[i].classList = [];
        this.$refs.cardm[i].classList = [];
        this.$refs.unknowncard[i].classList = [];
        this.$refs.unknowncardm[i].classList = [];
        this.$set(this.cardUrl, i, "");
      }
      this.saveMsg = "";
    },
    review() {
      this.successMsg = "";
      this.forbidden = true;
      this.axios
        .get(`${this.HOST}/divine/${this.id}`)
        .then((res) => {
          if (res.data.errno !== 0) {
            this.$Message.error(res.data.message)
            return
          }
          this.load = true;
          this.result = res.data.data.result.replace(/\[/g, '').replace(/\]/g, '').replace(/ /g, '').split(',')
          this.direct = res.data.data.direct.replace(/\[/g, '').replace(/\]/g, '').replace(/ /g, '').split(',')
          this.question = res.data.data.question
          this.question = this.question.trim();
          if(this.question  === '') {
            this.question = '该次抽取没有留下内容。'
          }
          this.successMsg = `唯一编号：<b>${res.data.data.id}</b><br>抽取时间：${res.data.data.create_time.replace('T', ' ').split('.')[0]}`
          if (this.$store.state.accountInfo.is_superuser) {
            this.successMsg += `<br>抽取用户：${res.data.accountInfo.username} (${res.data.accountInfo.nickname})<br>${res.data.data.ip}`
            if (res.data.data.ip.trim() !== '') {
              this.axios.get(`${this.HOST}/divine/ip?ip=${res.data.data.ip}`).then(res => {
                if (res.data.status === 'fail')
                  this.successMsg += ' - 未知'
                else 
                  this.successMsg += ` - ${res.data.country} ${res.data.province} ${res.data.city} ${res.data.district} ${res.data.isp}`
              })
            }
          }
          this.cnt = `${this.result.length}`;
          this.ready = 1;
          this.$nextTick(() => {
            for (let i = 0; i < this.cnt; i++) {
                this.$refs.card[i].classList = [];
                this.$refs.cardm[i].classList = [];
                this.$refs.unknowncard[i].classList = [];
                this.$refs.unknowncardm[i].classList = [];
            }
            setTimeout(() => {
              for (let i = 0; i < this.cnt; i++) {
                let direct = parseInt(this.direct[i]);
                if (direct === 0) {
                  this.$refs.card[i].classList = ["card"];
                  this.$refs.cardm[i].classList = ["card"];
                  this.$refs.unknowncard[i].classList = ["unknowncard"];
                  this.$refs.unknowncardm[i].classList = ["unknowncard"];
                } else {
                  this.$refs.card[i].classList = ["card-invert"];
                  this.$refs.cardm[i].classList = ["card-invert"];
                  this.$refs.unknowncard[i].classList = ["unknowncard"];
                  this.$refs.unknowncardm[i].classList = ["unknowncard"];
                }
                this.$set(
                  this.cardUrl,
                  i,
                  require(`@/assets/divine/${this.result[i]}.jpg`)
                );
              }
            })
          });
        });
    },
  },
  watch: {
    'result.length' (newV) {
      if (newV > 0) {
        this.preventChange = true
      } else {
        this.preventChange = false
      }
    },
    cnt(newV, oldV) {
      if (this.ready === 1) return;
      this.id = "";
      this.redo(oldV);
    },
    result() {
      if (this.result.length === parseInt(this.cnt) && this.forbidden === false) {
        this.axios
          .post(
            `${this.HOST}/divine/`, {
              result: this.result,
              direct: this.direct,
              question: this.question
            }
          )
          .then((res) => {
            if (res.data.errno === 0) {
              this.successMsg = `您已完成本次抽取。唯一编号：<b>${res.data.data.id}</b><br>您可在历史记录中复现该次抽取。`
            } else if (res.data.errno === 2) {
              this.successMsg = `您已完成本次抽取。唯一编号：<b>${res.data.data.id}</b><br>由于您未登录，本次抽取不会记录在个人账户中。`
            }
            this.getHistory()
          });
      }
    },
  },
};
</script>