<template>
<div style="width: 100%;display:flex;justify-content: center">
  <div style="width: 300px">
    <div style="margin: 50px;text-align: center"><h1>注册</h1></div>
    <Form
      ref="form"
      :model="accountInfo" 
      :rules="formRule">
      <FormItem label="用户名" prop="username">
        <Input placeholder="请仅包含大小写字母和数字" maxlength="16" v-model="accountInfo.username" />
      </FormItem>
      <FormItem label="密码" prop="password">
        <Input type="password" placeholder="" maxlength="32" v-model="accountInfo.password" />
      </FormItem>
      <FormItem label="昵称" prop="nickname">
        <Input maxlength="16" v-model="accountInfo.nickname" />
      </FormItem>
      <FormItem label="手机号" prop="phone">
        <Input placeholder="修改密码的唯一凭证，请确认无误" maxlength="11" v-model="accountInfo.phone" />
      </FormItem>
      <FormItem label=" ">
        <Button type="primary" style="width: 100%" @click="register">立即注册</Button>
      </FormItem>
    </Form>
  </div>
</div>
</template>
<script>
export default {
  data () {
    return {
      accountInfo: {
        username: '',
        password: '',
        nickname: '',
        phone: ''
      },
      formRule: {
        username: [{
          required: true,
          message: '用户名不能为空'
        },
        {
          min: 6,
          max: 16,
          message: '用户名长度须在6-16位之间'
        },
        {
          pattern: /^[a-zA-Z0-9]+$/,
          message: '请仅包含大小写字母和数字'
        }],
        nickname: [{
          required: true,
          message: '昵称不能为空'
        },
        {
          min: 4,
          max: 16,
          message: '昵称长度须在4-16位之间'
        }],
        password: [{
          required: true,
          message: '密码不能为空'
        },
        {
          min: 6,
          max: 32,
          message: '密码长度须在6-32位之间'
        }],
        phone: [{
          required: true,
          message: '手机号不能为空'
        },
        {
          pattern: /[0-9]{11}/,
          message: '手机号只能是11位数字'
        }]
      }
    }
  },
  methods: {
    register () {
      this.$refs.form.validate((valid) => {
        if(valid) {
          this.axios.post(`${this.HOST}/accounts/register/`, {
            username: this.accountInfo.username,
            password: this.accountInfo.password,
            phone: this.accountInfo.phone,
            nickname: this.accountInfo.nickname
          }).then(res => {
            this.$Message.info(res.data.message)
            this.$router.push('/login?next=/')
          })
        }
      })
    }
  }
}
</script>
