import Home from '@/views/Home'
import Meaning from '@/views/Meaning'
import MeaningDetail from '@/views/MeaningDetail'
import Divine from '@/views/Divine'
import DivineAstro from '@/views/DivineAstro'
import Modify from '@/views/Modify'
import Login from '@/views/Login'
import Register from '@/views/Register'
import Page404 from '@/views/404'
import VueRouter from 'vue-router'

const routes = [
  { path: '/', component: Home },
  { path: '/meaning', component: Meaning },
  { path: '/meaning/:id', component: MeaningDetail },
  { path: '/divine', component: Divine },
  { path: '/divineastro', component: DivineAstro },
  { path: '/modify', component: Modify },
  { path: '/login', component: Login },
  { path: '/register', component: Register },
  { path: '*', component: Page404 }
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

export default router