<template>
  <div
    style="
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 20px 0 20px 0;
      background-color: rgb(42, 49, 58);
      box-shadow: 0 -3px 5px #dbdbdb;
      height: 100px;
      font-size: small;
    "
  >
    <link
      rel="stylesheet"
      type="text/css"
      href="https://at.alicdn.com/t/font_1772505_mqxugqwo0pf.css"
    />
    <div
      style="
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        text-align: center;
        align-items: middle;
        color: grey;
      "
    >
      <div>Copyright 2020-2021, <a href="https://beian.miit.gov.cn" style="color: lightgrey">苏ICP备2021031048号</a></div>
      <div>Developed By <b style="color: lightgrey">Emerald</b> All rights reserved</div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Footer'
}
</script>