<template>
  <div>
    <div
      v-if="!$store.state.login"
      style="display: flex; justify-content: center; margin-top: 50px"
    >
      <div style="display: flex; flex-direction: column; align-items: center">
        <h2>用户验证</h2>
        <Input
          style="width: 300px; margin: 10px; margin-top: 20px"
          v-model="username"
          placeholder="username"
        ></Input>
        <Input
          style="width: 300px; margin: 10px"
          v-model="password"
          placeholder="password"
          type="password"
        ></Input>
        <Button
          style="width: 300px; margin: 10px"
          type="primary"
          @click="valid()"
          >登录</Button
        >
      </div>
    </div>
    <div v-else>
      <Table
        :columns="[
          {
            title: '序号',
            key: 'id',
            width: '150px',
          },
          {
            title: '卡牌名称',
            key: 'name',
          },
          {
            title: '英文名称',
            key: 'name_en',
          },
          {
            title: '操作',
            slot: 'opt',
            fixed: 'right',
          },
        ]"
        :data="decks"
      >
        <template slot-scope="{ index }" slot="opt">
          <Button type="primary" @click="modify(index)">修改</Button>
          <Button style="margin-left: 10px" type="info" @click="see(index)"
            >查看</Button
          >
        </template>
      </Table>
    </div>
    <Modal
      v-model="show"
      title="修改"
      fullscreen
      @on-ok="save()"
      @on-cancel="cancel()"
    >
      <Form :model="decks[idx]" label-position="left" :label-width="100">
        <FormItem label="卡牌名称">
          <Input v-model="decks[idx].name"></Input>
        </FormItem>
        <FormItem label="英文名称">
          <Input v-model="decks[idx].name_en"></Input>
        </FormItem>
        <FormItem label="关键词">
          <div id="edit_keyword"></div>
        </FormItem>
        <FormItem label="描述">
          <div id="edit_description"></div>
        </FormItem>
        <Divider>正位</Divider>
        <div id="edit_normal"></div>
        <Divider>逆位</Divider>
        <div id="edit_invert"></div>
      </Form>
    </Modal>
  </div>
</template>
<script>
import E from "wangeditor";
const { $, BtnMenu, DropListMenu } = E
class Head extends DropListMenu {
    constructor(editor) {
        const $elem = $('<div class="w-e-menu" data-title="字段">🔮</div>')

        // droplist 配置
        const dropListConf = {
            width: 100,
            title: '插入字段',
            type: 'list',
            list: [
                { $elem: $('<span>正位</span>'), value: '正位：' },
                { $elem: $('<span>逆位</span>'), value: '逆位：' },
                { $elem: $('<span>灵数</span>'), value: '灵数：' },
                { $elem: $('<span>占星意义</span>'), value: '占星意义：' },
            ],
            // droplist 每个 item 的点击事件
            clickHandler: (value) => {
                // value 参数即 dropListConf.list 中配置的 value
                this.command(value)
            },
        }

        super($elem, editor, dropListConf)
    }

    command(value) {
        // 设置标题
        this.editor.cmd.do('insertText', value)
    }

    // 菜单是否需要激活
    tryChangeActive() {
        const reg = /^h/i
        const cmdValue = this.editor.cmd.queryCommandValue('formatBlock')
        if (reg.test(cmdValue)) {
            // 选区处于标题内，激活菜单
            this.active()
        } else {
            // 否则，取消激活
            this.unActive()
        }
    }
}
class S18Menu extends BtnMenu {
    constructor(editor) {
        const $elem = E.$(
            `<div class="w-e-menu" data-title="调整字号为18px">
                <span style="font-weight: bold; color: #999">字</span>
            </div>`
        )
        super($elem, editor)
    }
    clickHandler() {
        this.editor.cmd.do('fontSize', 4)
    }
    tryChangeActive () {
    }
}
class H2Menu extends BtnMenu {
    constructor(editor) {
        const $elem = E.$(
            `<div class="w-e-menu" data-title="调整行高为2">
                <span style="font-weight: bold; color: #999">高</span>
            </div>`
        )
        super($elem, editor)
    }
    clickHandler() {
        this.editor.selection.getSelectionStartElem().elems[0].style.lineHeight = 2
        this.editor.selection.getSelectionEndElem().elems[0].style.lineHeight = 2
    }
}
class MyE extends E {
  constructor (editor) {
    super(editor)
    this.config.menus = [
      'bold',
      'fontSize',
      'fontName',
      'italic',
      'underline',
      'lineHeight',
      'foreColor',
      'justify'
      ]
  }
}
// 菜单 key ，各个菜单不能重复
const S18MenuKey = 's18'
const H2MenuKey = 'h2'
// 注册菜单
MyE.registerMenu(S18MenuKey, S18Menu)
MyE.registerMenu(H2MenuKey, H2Menu)
export default {
  name: "modify",
  data() {
    return {
      username: "",
      password: "",
      decks: [{}],
      show: false,
      idx: 0,
      edit_keyword: null,
      edit_description: null,
      edit_normal: null,
      edit_invert: null,
    };
  },
  created() {
    if (window.localStorage.getItem("login") === "t") {
      this.$store.commit("loginState", true);
      this.read_json();
    }
  },
  methods: {
    valid() {
      if (this.username === "root" && this.password === "65204471Yjj") {
        this.$store.commit("loginState", true);
        window.localStorage.setItem("login", "t");
        this.$Message.success("认证成功");
        this.read_json();
      } else {
        this.$Message.error("用户名或密码错误");
      }
    },
    read_json() {
      this.axios.get("/api/decks.json").then((res) => {
        this.decks = res.data;
      });
    },
    modify(row) {
      this.show = true;
      this.idx = row;
      this.edit_keyword = new MyE("#edit_keyword");
      this.edit_description = new MyE("#edit_description");
      this.edit_normal = new MyE("#edit_normal");
      this.edit_invert = new MyE("#edit_invert");
      this.edit_keyword.menus.extend('Head123', Head);
      this.edit_keyword.config.menus.push('Head123');
      this.edit_keyword.create();
      this.edit_keyword.txt.html(this.decks[row].keyword);
      this.edit_description.create();
      this.edit_description.txt.html(this.decks[row].description);
      this.edit_normal.create();
      this.edit_normal.txt.html(this.decks[row].normal);
      this.edit_invert.create();
      this.edit_invert.txt.html(this.decks[row].invert);
    },
    save() {
      this.decks[this.idx].keyword = this.edit_keyword.txt.html();
      this.decks[this.idx].description = this.edit_description.txt.html();
      this.decks[this.idx].normal = this.edit_normal.txt.html();
      this.decks[this.idx].invert = this.edit_invert.txt.html();
      this.edit_keyword.destroy();
      this.edit_description.destroy();
      this.edit_normal.destroy();
      this.edit_invert.destroy();
      this.axios
        .post("http://47.103.196.191:81/save.php", this.decks)
        .then((res) => {
          if (res.data === "ok") this.$Message.success("保存成功");
        });
    },
    cancel() {
      this.edit_keyword.destroy();
      this.edit_description.destroy();
      this.edit_normal.destroy();
      this.edit_invert.destroy();
    },
    see(index) {
      window.open(`/meaning/${index}`, "_blank");
    },
  },
};
</script>
