<template>
  <div>
    <div class="pc" style="text-align: center">
      <div style="margin-top: 15px">
        <Button
          type="primary"
          style="margin-right: 10px"
          @click="$router.push('/divine')"
          >塔罗牌</Button
        >
        <Button type="success">占星骰子</Button>
      </div>
      <div style="margin-top: 50px; margin-bottom: 20px">
        <div
          v-if="this.$store.state.accountInfo.is_superuser"
          style="margin: 20px"
        >
          <Input style="width: 300px" placeholder="唯一编号" v-model="id" />
          <Button style="margin-left: 10px" type="primary" @click="review"
            >查询</Button
          >
        </div>
        <div>
          <Button
            style="width: 200px"
            type="info"
            @click="resolve"
            :disabled="result.length !== 0"
            >开始</Button
          >
          <!-- <Button
          :disabled="!this.$store.state.accountInfo.username"
          style="margin-left: 10px"
          type="info"
          @click="drawer = true"
          >历史记录</Button
        > -->
        </div>
        <div>
          <div v-if="load === false">
            <Input
              :disabled="result.length !== 0"
              v-model="question"
              maxlength="1000"
              show-word-limit
              type="textarea"
              :placeholder="'请先输入您的问题，之后再点开始按钮。'"
              style="margin-top: 20px; width: 600px"
            />
          </div>
          <div
            v-else
            style="
              padding: 50px 100px 20px 100px;
              word-break: break-all;
              word-wrap: break-word;
            "
          >
            <span v-html="question" />
          </div>
        </div>
      </div>
      <div style="display: flex; justify-content: center">
        <div
          v-if="result.length === 0"
          style="
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            width: 650px;
          "
        >
          <div
            style="position: relative; margin: 15px; padding: 0; height: 100px; width: 100px"
            v-for="idx in 3"
            :key="idx"
          >
            <img
              class="card"
              ref="card"
              src="@/assets/star.png"
              style="padding: 0; cursor: pointer"
              height="100"
            />
          </div>
        </div>
        <div
          v-else
          style="
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            width: 650px;
          "
        >
          <div style="position: relative; margin: 15px; height: 100px; width: 100px">
            <img
              class="card"
              ref="card"
              :src="require(`@/assets/astro/xingzuo/${result[0]}.png`)"
              style="border-radius: 5px; cursor: pointer"
              height="100"
            />
            <div>
              <span> {{ astro[0].label[result[0]] }} </span>
            </div>
          </div>
          <div style="position: relative; margin: 15px; height: 100px; width: 100px">
            <img
              class="card"
              ref="card"
              :src="require(`@/assets/astro/xingxing/${result[1]}.png`)"
              style="border-radius: 5px; cursor: pointer"
              height="100"
            />
            <div>
              <span> {{ astro[1].label[result[1]] }} </span>
            </div>
          </div>
          <div style="position: relative; margin: 15px; height: 100px; width: 100px">
            <div
              class="card"
              style="
                width: 100px;
                height: 100px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 5px;
              "
            >
              <span style="font-size: 32pt; color: rgb(222, 196, 142)">{{
                result[2] + 1
              }}</span>
            </div>
            <div>
              <span> {{ astro[2].label[result[2]] }} </span>
            </div>
          </div>
        </div>
      </div>
      <div style="margin: 20px">
        <span v-html="successMsg"></span>
      </div>
    </div>
    <div class="mobile">
      <div style="margin-top: 15px; display: flex; justify-content: center">
        <Button
          type="primary"
          style="margin-right: 10px"
          @click="$router.push('/divine')"
          >塔罗牌</Button
        >
        <Button type="success">占星骰子</Button>
      </div>
      <div
        style="
          display: flex;
          justify-content: center;
          margin: 40px 50px 10px 50px;
        "
      >
        <div v-if="this.$store.state.accountInfo.is_superuser">
          <Input style="width: 150px" placeholder="唯一编号" v-model="id" />
          <Button
            style="width: 100px; margin-left: 10px"
            type="primary"
            @click="review"
            >查询</Button
          >
        </div>
      </div>
      <div
        style="
          display: flex;
          justify-content: center;
          margin: 10px 50px 10px 50px;
        "
      >
        <Button
          style="width: 30%"
          type="info"
          @click="resolve"
          :disabled="result.length !== 0"
          >解</Button
        >
        <!-- <Button
          :disabled="!this.$store.state.accountInfo.username"
          style="width: 25%; margin-left: 10px"
          type="info"
          @click="drawer = true"
          >历史记录</Button
        > -->
      </div>
      <div
        style="
          display: flex;
          justify-content: center;
          margin: 10px 50px 20px 50px;
        "
      >
        <div style="width: 100%" v-if="load === false">
          <Input
            :disabled="result.length !== 0"
            v-model="question"
            maxlength="1000"
            show-word-limit
            type="textarea"
            :placeholder="'请先输入您的问题，之后再点解。'"
            style="width: 100%"
          />
        </div>
        <div
          v-else
          style="padding: 10px; word-break: break-all; word-wrap: break-word"
        >
          <span v-html="question" />
        </div>
      </div>
      <div>
        <div style="display: flex; justify-content: center">
          <div
            v-if="result.length === 0"
            style="
              display: flex;
              justify-content: space-between;
              flex-wrap: nowrap;
              width: 650px;
              padding: 0 50px 0 50px;
            "
          >
            <div
              style="position: relative; margin: 15px 5px 15px 5px;height: 100px; width: 100px"
              v-for="idx in 3"
              :key="idx"
            >
              <img
                class="card"
                ref="card"
                src="@/assets/star.png"
                style="padding: 0; cursor: pointer"
                height="100"
              />
            </div>
          </div>
          <div
            v-else
            style="
              display: flex;
              justify-content: space-between;
              flex-wrap: nowrap;
              width: 650px;
              padding: 0 50px 0 50px;
            "
          >
            <div style="position: relative; margin: 15px 5px 15px 5px;height: 100px; width: 100px">
              <img
                class="card"
                ref="card"
                :src="require(`@/assets/astro/xingzuo/${result[0]}.png`)"
                style="border-radius: 5px; cursor: pointer"
                height="100"
              />
              <div style="display: flex; justify-content: center">
                <span> {{ astro[0].label[result[0]] }} </span>
              </div>
            </div>
            <div style="position: relative; margin: 15px 5px 15px 5px;height: 100px; width: 100px">
              <img
                class="card"
                ref="card"
                :src="require(`@/assets/astro/xingxing/${result[1]}.png`)"
                style="border-radius: 5px; cursor: pointer"
                height="100"
              />
              <div style="display: flex; justify-content: center">
                <span> {{ astro[1].label[result[1]] }} </span>
              </div>
            </div>
            <div style="position: relative; margin: 15px 5px 15px 5px;">
              <div
                class="card"
                style="
                  width: 100px;
                  height: 100px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin-bottom: 5px;
                "
              >
                <span style="font-size: 32pt; color: rgb(222, 196, 142)">{{
                  result[2] + 1
                }}</span>
              </div>
              <div style="display: flex; justify-content: center">
                <span> {{ astro[2].label[result[2]] }} </span>
              </div>
            </div>
          </div>
        </div>
        <div style="margin: 20px; text-align: center">
          <span v-html="successMsg"></span>
        </div>
      </div>
      <Drawer title="历史记录" :closable="false" v-model="drawer">
        <div
          class="history-item"
          style="cursor: pointer; padding: 10px"
          @click.stop="
            id = item.id;
            review();
            drawer = false;
          "
          v-for="(item, index) in history"
          :key="item.id"
        >
          <div>
            <span
              ><b>唯一编号：{{ item.id }}</b></span
            >
          </div>
          <div style="overflow: hidden">
            <span style="color: grey">{{
              item.question.length > 12
                ? `${item.question.substr(0, 12)}...`
                : `${item.question}`
            }}</span>
          </div>
          <div>{{ item.create_time }}</div>
          <div
            style="
              display: flex;
              align-items: center;
              width: 200px;
              flex-wrap: wrap;
              margin-top: 5px;
            "
          >
            <div
              style="margin-right: 10px"
              v-for="(imgIndex, idx) in item.result
                .replace(/\[/g, '')
                .replace(/\]/g, '')
                .replace(/ /g, '')
                .split(',')"
              :key="index * 100 + imgIndex"
            >
              <img
                style="
                  border: 3px solid white;
                  border-radius: 3px;
                  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
                "
                v-if="
                  item.direct
                    .replace(/\[/g, '')
                    .replace(/\]/g, '')
                    .replace(/ /g, '')
                    .split(',')[idx] === '0'
                "
                width="55px"
                :src="require(`@/assets/divine/${imgIndex}.jpg`)"
              />
              <img
                style="
                  transform: rotate(180deg);
                  border: 3px solid white;
                  border-radius: 3px;
                  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
                "
                width="55px"
                v-else
                :src="require(`@/assets/divine/${imgIndex}.jpg`)"
              />
            </div>
          </div>
        </div>
      </Drawer>
    </div>
    <Modal v-model="meaningModal" :title="selectCardTitle" cancel-text="">
      <p v-html="selectCardMeaning"></p>
    </Modal>
  </div>
</template>
<style scoped>
::v-deep
  .ivu-radio-group-button
  .ivu-radio-wrapper-disabled.ivu-radio-wrapper-checked {
  background: #2d8cf0;
}
::v-deep .ivu-select-disabled .ivu-select-selection {
  color: black;
}
.history-item:hover {
  background: lightgrey;
  border-radius: 5px;
  transition: 0.25s all ease;
}
.mobile {
  display: none;
}
@media only screen and (max-width: 768px) {
  .pc {
    display: none;
  }
  .mobile {
    display: block;
  }
}
.card {
  height: 100px;
  width: 100px;
  padding: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  border-radius: 100px !important;
}
</style>
<script>
export default {
  name: "Divine",
  data() {
    return {
      astro: [],
      numList: [0, 1, 2, 3, 4, 5, 6, 7, 8],
      cardUrl: new Array(),
      exists: [],
      result: [],
      direct: [],
      successMsg: "",
      id: "",
      question: "",
      load: false,
      history: [],
      drawer: false,
      forbidden: false,
      preventChange: false,
      meaningModal: false,
      selectCardTitle: "",
      selectCardMeaning: "",
    };
  },
  created() {
    this.axios.get("/assets/astro.json").then((res) => {
      this.astro = res.data;
    });
  },
  mounted() {
    this.getHistory();
  },
  methods: {
    resolve() {
      let a = Math.floor((Math.random() * 12) % 12);
      let b = Math.floor((Math.random() * 12) % 12);
      let c = Math.floor((Math.random() * 12) % 12);
      this.result = [a, b, c];
    },
    getHistory() {
      this.axios.get(`${this.HOST}/divine/`).then((res) => {
        this.history = res.data.data;
        for (const idx in this.history) {
          this.history[idx].create_time = this.history[idx].create_time
            .replace("T", " ")
            .split(".")[0];
        }
      });
    },
    redo(oldV) {
      this.exists = [];
      this.result = [];
      this.direct = [];
      this.load = false;
      this.question = "";
      this.successMsg = "";
      this.forbidden = false;
      for (let i = 0; i < oldV; i++) {
        this.$set(this.cardUrl, i, "");
      }
      this.saveMsg = "";
    },
  },
};
</script>