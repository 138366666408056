import Vue from 'vue'
import App from './App.vue'
import ViewUI from 'view-design'
import 'view-design/dist/styles/iview.css'
import axios from 'axios'
import VueRouter from 'vue-router'
import Vuex from 'vuex'
import router from '@/router'
import store from '@/store'
import HOST from '@/utils/setting.js'
axios.defaults.withCredentials=true
document.title = '爱塔罗'

Vue.use(VueRouter)
Vue.use(ViewUI)
Vue.use(Vuex)

router.beforeEach((to, from, next) => {
  if (to.fullPath === '/') {
    document.title = '首页 | 爱塔罗'
  } else if (to.fullPath === '/meaning') {
    document.title = `牌意 | 爱塔罗`
  } else if (to.fullPath === '/divine') {
    document.title = '在线占卜 | 爱塔罗'
  }
  next()
})

Vue.prototype.axios = axios
Vue.prototype.HOST = HOST

new Vue({
  render: h => h(App), 
  router,
  store
}).$mount('#app')

router.afterEach(() => {
  window.scrollTo(0,0)
})