<template>
  <div>
    <div style="padding-top: 80px">
      <div style="display: flex; justify-content: center; padding: 0 10%">
        <h1>塔罗牌意</h1>
      </div>
      <div style="display: flex; justify-content: center; flex-wrap: wrap; padding: 0 10%">
        <a
          class="ivu-tag ivu-tag-size-large ivu-tag-success ivu-tag-checked tag"
          type="success"
          style="margin: 5px"
          href="#major_arcana"
          >大阿尔卡纳</a
        >
        <a
          class="ivu-tag ivu-tag-size-large ivu-tag-success ivu-tag-checked tag"
          type="success"
          style="margin: 5px"
          href="#wands"
          >权杖卡组</a
        >
        <a
          class="ivu-tag ivu-tag-size-large ivu-tag-success ivu-tag-checked tag"
          type="success"
          style="margin: 5px"
          href="#pentacles"
          >星币卡组</a
        >
        <a
          class="ivu-tag ivu-tag-size-large ivu-tag-success ivu-tag-checked tag"
          type="success"
          style="margin: 5px"
          href="#cups"
          >圣杯卡组</a
        >
        <a
          class="ivu-tag ivu-tag-size-large ivu-tag-success ivu-tag-checked tag"
          type="success"
          style="margin: 5px"
          href="#swords"
          >宝剑卡组</a
        >
      </div>
      <!-- 右下 -->
      <div :class="menuClass">
        <a
          class="ivu-tag ivu-tag-size-large ivu-tag-success ivu-tag-checked tag"
          type="success"
          style="margin: 5px; height: 40px; width: 48px"
          href="#major_arcana"
          title="大阿尔卡纳"
          ><img src="@/assets/cards.png" width="22px" style="padding-top: 10px"
        /></a>
        <a
          class="ivu-tag ivu-tag-size-large ivu-tag-success ivu-tag-checked tag"
          type="success"
          style="margin: 5px; height: 40px; width: 48px"
          href="#wands"
          title="权杖卡组"
          ><img src="@/assets/wand.png" width="22px" style="padding-top: 10px"
        /></a>
        <a
          class="ivu-tag ivu-tag-size-large ivu-tag-success ivu-tag-checked tag"
          type="success"
          style="margin: 5px; height: 40px; width: 48px"
          href="#pentacles"
          title="星币卡组"
          ><img src="@/assets/coin.png" width="22px" style="padding-top: 10px"
        /></a>
        <a
          class="ivu-tag ivu-tag-size-large ivu-tag-success ivu-tag-checked tag"
          type="success"
          style="margin: 5px; height: 40px; width: 48px"
          href="#cups"
          title="圣杯卡组"
          ><Icon type="ios-trophy" style="font-size: 16pt; padding-top: 10px"
        /></a>
        <a
          class="ivu-tag ivu-tag-size-large ivu-tag-success ivu-tag-checked tag"
          type="success"
          style="margin: 5px; height: 40px; width: 48px"
          href="#swords"
          title="宝剑卡组"
          ><img src="@/assets/sword.png" width="22px" style="padding-top: 10px"
        /></a>
      </div>
    </div>

    <div>
      <div style="padding: 5% 15% 5% 15%" id="major_arcana">
        <section>
          <Divider><h2>大阿尔卡纳</h2></Divider>
          <div>
            <article>
              <p>
                标准塔罗牌有78张卡牌组成，分为两部分：主牌（大阿尔卡纳）和辅牌（小阿尔卡纳）。“阿尔卡纳”（arcana）是arcanum的复数形式，而后者的英文解释为“奥秘”。22张大阿尔卡纳是塔罗牌的核心，均有其特定的含义所在。56张小阿尔卡纳是对大阿尔卡纳的诠释，依据西方的四元素学说分为四组——权杖（火）、圣杯（水）、宝剑（风）、钱币（土）。
              </p>
              <p>
                大阿尔卡纳——78张塔罗牌中的22张大牌，代表着人生的重大课题。从愚人牌到世界牌的过程，就是人生从混沌初生到整合归一的过程。当一个人从“愚人”走向“世界”，也就完成了个人成长的英雄之旅。
              </p>
            </article>
          </div>
        </section>
      </div>
      
      <div style="display: flex">
        <div class="card-group">
          <Card
            v-for="item of decks.slice(0, 22)"
            :key="item.name"
            class="card"
            @click.native="$router.push(`/meaning/${item.id}`)"
          >
            <img
              style="margin: 5px"
              :src="require(`@/assets/deck/${item.image}.jpg`)"
              width="150px"
            />
            <Divider><h2>{{ item.name }}</h2></Divider>
            <h3>{{ item.name_en }}</h3>
            <!-- <div
              style="text-align: left; margin-left: 20px; margin-right: 10px"
            >
              <span><Divider>关键词</Divider><span v-html="item.keyword"></span></span>
            </div>
            <Button
              class="card-button"
              ghost
              long
              type="success"
              style="position: absolute; left: 0px; bottom: 0"
              :to="`/meaning/${item.id}`"
              >查看详情
            </Button> -->
          </Card>
        </div>
      </div>

      <div style="padding: 5% 15% 5% 15%" id="wands">
        <section>
          <Divider><h2>权杖卡组</h2></Divider>
          <div>
            <article>
              <p>
                权杖牌组代表火元素，火，关系到能量、狂热、行动、生命力，活力。投入全部精力体验全然的欢喜。靠征服来发展，喜欢挑战更胜于奖赏。火很自然向上跳跃，一方面寻求燃料，一方面不断扩张。它代表乐观、自信和希望。
              </p>
            </article>
          </div>
        </section>
      </div>

      <div style="display: flex">
        <div class="card-group">
          <Card
            v-for="item of decks.slice(22, 36)"
            :key="item.name"
            class="card"
            @click.native="$router.push(`/meaning/${item.id}`)"
          >
            <img
              style="margin: 5px"
              :src="require(`@/assets/deck/${item.image}.jpg`)"
              width="150px"
            />
            <Divider><h2>{{ item.name }}</h2></Divider>
            <h3>{{ item.name_en }}</h3>
            <!-- <div
              style="text-align: left; margin-left: 20px; margin-right: 10px"
            >
              <span><Divider>关键词</Divider><span v-html="item.keyword"></span></span>
            </div>
            <Button
              class="card-button"
              ghost
              long
              type="success"
              style="position: absolute; left: 0px; bottom: 0"
              :to="`/meaning/${item.id}`"
              >查看详情
            </Button> -->
          </Card>
        </div>
      </div>

      <div style="padding: 5% 15% 5% 15%" id="pentacles">
        <section>
          <Divider><h2>星币卡组</h2></Divider>
          <div>
            <article>
              <p>
                星币代表土元素，土所表示的是真实的、物质的东西，他们喜欢大自然，崇尚实际，喜欢生意往来和活在当下。他们对于自己的生活更能处之泰然，通常显得健康、稳重及脚踏实地。
              </p>
            </article>
          </div>
        </section>
      </div>

      <div style="display: flex">
        <div class="card-group">
          <Card
            v-for="item of decks.slice(36, 50)"
            :key="item.name"
            class="card"
            @click.native="$router.push(`/meaning/${item.id}`)"
          >
            <img
              style="margin: 5px"
              :src="require(`@/assets/deck/${item.image}.jpg`)"
              width="150px"
            />
            <Divider><h2>{{ item.name }}</h2></Divider>
            <h3>{{ item.name_en }}</h3>
            <!-- <div
              style="text-align: left; margin-left: 20px; margin-right: 10px"
            >
              <span><Divider>关键词</Divider><span v-html="item.keyword"></span></span>
            </div>
            <Button
              class="card-button"
              ghost
              long
              type="success"
              style="position: absolute; left: 0px; bottom: 0"
              :to="`/meaning/${item.id}`"
              >查看详情
            </Button> -->
          </Card>
        </div>
      </div>

      <div style="padding: 5% 15% 5% 15%" id="cups">
        <section>
          <Divider><h2>圣杯卡组</h2></Divider>
          <div>
            <article>
              <p>
                圣杯牌组代表的是水元素，代表爱和想象。水元素告诉我们：爱是通往心灵满足的途径，不论是付出或接受，爱都很重要。圣杯的典型态度是透过爱来给予和接受，这使得友谊或两性关系更加亲密，彼此更互信互赖。
              </p>
            </article>
          </div>
        </section>
      </div>

      <div style="display: flex">
        <div class="card-group">
          <Card
            v-for="item of decks.slice(50, 64)"
            :key="item.name"
            class="card"
            @click.native="$router.push(`/meaning/${item.id}`)"
          >
            <img
              style="margin: 5px"
              :src="require(`@/assets/deck/${item.image}.jpg`)"
              width="150px"
            />
            <Divider><h2>{{ item.name }}</h2></Divider>
            <h3>{{ item.name_en }}</h3>
            <!-- <div
              style="text-align: left; margin-left: 20px; margin-right: 10px"
            >
              <span><Divider>关键词</Divider><span v-html="item.keyword"></span></span>
            </div>
            <Button
              class="card-button"
              ghost
              long
              type="success"
              style="position: absolute; left: 0px; bottom: 0"
              :to="`/meaning/${item.id}`"
              >查看详情
            </Button> -->
          </Card>
        </div>
      </div>

      <div style="padding: 5% 15% 5% 15%" id="swords">
        <section>
          <Divider><h2>宝剑卡组</h2></Divider>
          <div>
            <article>
              <p>
                宝剑这组牌代表风元素或智力，刀剑可以带来痛苦，然而也可以带来理解力和创新。这组牌是关于我们如何通过试炼而改变我们对人生的理解。想法、理念、信仰及态度都是以宝剑这组牌为代表。
              </p>
            </article>
          </div>
        </section>
      </div>

      <div style="display: flex">
        <div class="card-group">
          <Card
            v-for="item of decks.slice(64, 78)"
            :key="item.name"
            class="card"
            @click.native="$router.push(`/meaning/${item.id}`)"
          >
            <img
              style="margin: 5px"
              :src="require(`@/assets/deck/${item.image}.jpg`)"
              width="150px"
            />
            <Divider><h2>{{ item.name }}</h2></Divider>
            <h3>{{ item.name_en }}</h3>
            <!-- <div
              style="text-align: left; margin-left: 20px; margin-right: 10px"
            >
              <span><Divider>关键词</Divider><span v-html="item.keyword"></span></span>
            </div>
            <Button
              class="card-button"
              ghost
              long
              type="success"
              style="position: absolute; left: 0px; bottom: 0"
              :to="`/meaning/${item.id}`"
              >查看详情
            </Button> -->
          </Card>
        </div>
      </div>
    </div>
    <div class="mobile">
    </div>
  </div>
</template>
<style scoped>
@keyframes flipcard {
  from {
    transform: rotateY(0deg);
    opacity: 1;
    color: white;
  }
  to {
    transform: rotateY(180deg);
    color: white;
    opacity: 0;
  }
}

.menu {
  display: none;
}

.tag:hover {
  color: white;
}

.card {
  margin: 20px;
  position: relative;
  width: 250px;
  height: 410px;
  text-align: center;
  box-shadow: 5px 5px 10px #eeeeee;
  cursor: pointer;
}
.card:hover {
  box-shadow: 5px 5px 20px #cccccc;
}

.card-button:hover {
  color: white;
  background-color: #19be6b;
}

h2 {
  font-size: 18pt;
}
p {
  font-size: 14pt;
  line-height: 30px;
  text-align: justify;
}
@media screen and (max-width: 768px) {
  .card-group {
    display: flex;
    flex-wrap: nowrap;
    overflow: auto;
  }
  .card-group:before {
    width: 20px;
    height: 100px;
    flex-shrink: 0;
    content: ""
  }
  .card-group:after {
    width: 20px;
    height: 100px;
    flex-shrink: 0;
    content: ""
  }
  .menu-fixed {
    position: fixed;
    display: flex;
    text-align: center;
    left: 20px;
    bottom: 25px;
    z-index: 10;
  }
}
@media screen and (min-width: 768px) {
  .card-group {
    max-width: 1366px;
    position: relative;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding-left: 100px;
    padding-right: 100px;
  }
  .card-group:after {
    width: 580px;
    height: 500px;
    content: "";
  }
  .menu-fixed {
    position: fixed;
    display: flex;
    flex-flow: column;
    text-align: center;
    right: 25px;
    bottom: 80px;
    z-index: 10;
  }

}
</style>
<script>
export default {
  data() {
    return {
      decks: [],
      menuClass: "menu",
    };
  },
  methods: {
    handleScroll() {
      let pageYOffset = window.pageYOffset;
      if (pageYOffset > 158) {
        this.menuClass = "menu-fixed";
      } else {
        this.menuClass = "menu";
      }
    },
  },
  created() {
    this.axios.get("/assets/decks.json").then((res) => {
      this.decks = res.data;
    });
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>