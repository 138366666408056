<template>
  <div id="app" class="app">
      <Navbar />
      <transition name="fade" mode="out-in">
        <router-view style="padding-top:60px;padding-bottom: 150px" :key="$route.path" />
      </transition>
      <Footer />
      <BackTop :height="158"></BackTop>
  </div>
</template>
<style scoped>
  .app {
    position:relative;
    min-height:100vh;
    height:auto
  }
</style>
<script>
import Navbar from '@/components/Navbar'
import Footer from '@/components/Footer'
export default {
  name: 'App',
  created () {
    const info = JSON.parse(localStorage.getItem('accountInfo'))
    this.axios.get(`${this.HOST}/accounts/status`).then(res => {
      let errno = res.data.errno
      if (info && !errno) {
        this.$store.commit('loginSuccess', info)
      } else {
        localStorage.removeItem('accountInfo')
      }
    })

  },
  components: {
    Navbar,
    Footer
  }
}
</script>
