<template>
  <div>
    <div class="bookmark">
      <Button class="bookmark-btn" icon="md-bookmarks" size="large" @click="drawer = true">查看目录</Button>
    </div>
    <!-- <div v-if="pre.name" style="position:fixed; top: 80px;left: 10px">
        <span style="color: #2d8cf0;cursor: pointer" @click="$router.push(`/meaning/${pre.id}`)"><Icon type="ios-arrow-back"></Icon>上一张牌</span> 
        <h3>{{ pre.name }}</h3>
        <h4>{{ pre.name_en }}</h4>
    </div>
    <div v-if="next.name" style="position:fixed; top: 80px;right: 10px;text-align: right">
        <span style="color: #2d8cf0;cursor: pointer" @click="$router.push(`/meaning/${next.id}`)">下一张牌<Icon type="ios-arrow-forward"></Icon></span>
        <h3>{{ next.name }}</h3>
        <h4>{{ next.name_en }}</h4>
    </div> -->
    <div style="display:flex;justify-content:center">
      <div class="top-banner">
        <div style="display: flex;justify-content: space-around; align-items: center">
          <img id="deckimg" style="box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);border-radius: 7px" :src="imgUrl" height="250" />
          <div style="margin: 20px;text-align:center">
            <h1>{{ info.name }}</h1>
            <h2>{{ info.name_en }}</h2>
            <Divider><Icon style="color: #c57600" type="md-key" /></Divider>
            <div v-html="info.keyword" style="text-align: left"></div>
          </div>
        </div>
      </div>
    </div>
    <div style="display:flex;justify-content:center">
      <div class="bottom-banner">
        <Divider orientation="center"><h3>描<div style="display:inline-block;width: 1em"></div>述</h3></Divider>
        <p v-html="info.description"></p>
        <Divider orientation="center"><h3>正<div style="display:inline-block;width: 1em"></div>位</h3></Divider>
        <div v-html="info.normal"></div>
        <Divider orientation="center"><h3>逆<div style="display:inline-block;width: 1em"></div>位</h3></Divider>
        <div v-html="info.invert"></div>
      </div>
    </div>
    <Drawer title="目录" :closable="false" v-model="drawer">
        <div style="line-height: 2rem;font-size: 11pt" v-for="item of data" :key="item.id">
          <div v-if="item.id !== $route.params.id" style="margin-bottom: 10px">
            <router-link style="color:black" :to="item.id"><span>{{ item.name }}</span> - <span>{{item.name_en}}</span></router-link>
          </div>
          <div v-else style="margin-bottom: 10px;color: #2d8cf0;font-weight:bold;font-size: 12pt">
            <span>{{ item.name }}</span> - <span>{{item.name_en}}</span>
          </div>
        </div>
    </Drawer>
  </div>
</template>
<style scoped>
@media screen and (max-width: 768px) {
  .bookmark {
    position:fixed;
    left:100px;
    top:10px;
    z-index: 2;
  }
  .bookmark-btn {
    background: rgba(0,0,0,0);
    color: white;
  }
  .top-banner {
    padding: 20px;
  }
  .bottom-banner {
    padding: 20px;
  }
}
@media screen and (min-width: 768px) {
  .bookmark {
    position:fixed;
    right:10px;
    top:70px
  }
  .top-banner {
    margin: 50px;
    min-width: 300px;
    width: 50%
  }
  .bottom-banner {
    min-width: 600px;
    width:50%
  }
}
</style>
<script>
export default {
  data () {
    return {
      data: [],
      info: {},
      imgUrl: '',
      drawer: false
    }
  },
  created () {
    this.axios.get('/assets/decks.json').then(res => {
      this.data = res.data
      for (const item of res.data) {
        if (item.id === this.$route.params.id) {
          this.info = item
          document.title = `牌意 - ${this.info.name} | 爱塔罗`
          this.imgUrl = require(`@/assets/deck/${this.info.image}.jpg`)
        }
      }
    })
  }
}
</script>